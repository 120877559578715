/*POSTS COLLECTIONS*/
import { buildCollection, buildProperty, EntityReference } from "firecms";

type Post = {
  type: string;
  visibility: string;
  title: string;
  description: string;
  creator: EntityReference;
  image: string;
  ingredients: EntityReference[];
  tags: EntityReference[];
  key: EntityReference;
  variationOf: EntityReference;
  recipe: EntityReference;
  linkUrl: string;
  linkText: string;
  created: Date;
  updated: Date;
}

export const posts = buildCollection<Post>({
  name: "All Posts",
  icon: "LocalFireDepartment",
  group: "User Content",
  singularName: "Post",
  path: "posts",
  initialSort: ["created", "desc"], 
  textSearchEnabled: true,
  permissions: ({ authController }) => ({
      edit: true,
      create: true,
      delete: true
  }),
  properties: {
    type: {
      name: "Type",
      dataType: "string",
      validation: { required: true },
      description: "Preset",
      longDescription: "",
      enumValues: {
        creation: "Creation",
        combination: "Combination",
        discussion: "Discussion",
        announcement: "Announcement",
      },
      defaultValue: "creation",
    },
    visibility: {
      name: "Visibility",
      dataType: "string",
      enumValues: [
        { id: "public", label: "Public", color: "grayLighter" },
        { id: "suppressed", label: "Suppressed", color: "yellowLight" },
        { id: "hidden", label: "Hidden", color: "redDark" },
      ],
      defaultValue: "public",
      validation: { required: true },
    },
    title: {
      name: "Title",
      dataType: "string",
      validation: { required: true },
    },
    description: {
      name: "Description",
      dataType: "string",
      multiline: true,
    },
    creator: {
      name: "Creator",
      dataType: "reference",
      path: "users",
      validation: { required: true },
    },
    image: buildProperty({ 
      name: "Image",
      dataType: "string",
      storage: {
          storagePath: "images",
          acceptedFiles: ["image/*"],
          storeUrl: true,
      },
    }),
    ingredients: {
      name: "Ingredients",
      description: "For creations only",
      dataType: "array",
      of: {
        dataType: "reference",
        path: "items",
        forceFilter: {
          type: ["in", ['ingredient','category']],
        },
      },
    },
    tags: {
      name: "Tags",
      description: "Categories, techniques, regions etc.",
      dataType: "array",
      of: {
        dataType: "reference",
        path: "items",
        forceFilter: {
          type: ["!=", "ingredient"],
        },
      },
      validation: { required: true },
    },
    key: {
      name: "Key",
      description: "For combinations only",
      dataType: "reference",
      path: "items",
      forceFilter: {
        type: ["in", ['ingredient','category', 'region', 'technique', 'style']],
      },
    },
    variationOf: {
      name: "Variation Of",
      description: "For creations only",
      dataType: "reference",
      path: "items",
      forceFilter: {
        type: ["==", "creation"],
      },
    },
    recipe: {
      name: "Recipe",
      description: "For creations only",
      dataType: "reference",
      path: "recipes",
    },
    linkText: {
      name: "Link Text",
      description: "Enter a link title",
      dataType: "string",
      defaultValue: "Recipe",
    },
    linkUrl: {
      name: "Link URL",
      description: "Enter a link URL",
      dataType: "string",
      url: true,
    },
    created: {
      name: "Created",
      dataType: "date",
      validation: { required: true },
      autoValue: "on_create",
    },
    updated: {
      name: "Updated",
      dataType: "date",
      autoValue: "on_update",
    },
  },
});

type CreationPost = {
  type: string;
  visibility: string;
  title: string;
  description: string;
  creator: EntityReference;
  image: string;
  variationOf: EntityReference;
  ingredients: EntityReference[];
  tags: EntityReference[];
  recipe: EntityReference;
  linkUrl: string;
  linkText: string;
  created: Date;
  updated: Date;
}

export const creationPosts = buildCollection<CreationPost>({
  name: "Creations",
  icon: "DinnerDining",
  group: "User Content",
  singularName: "Creation",
  path: "posts",
  alias: "creations",
  forceFilter: { type: ["==", "creation"] },
  // initialSort: ["created", "desc"], //doesn't work in conjunction with force filter
  textSearchEnabled: true,
  permissions: ({ authController }) => ({
      edit: true,
      create: true,
      delete: true
  }),
  properties: {
    type: {
      name: "Type",
      dataType: "string",
      validation: { required: true },
      description: "Preset",
      longDescription: "",
      enumValues: {
        creation: "Creation",
      },
      defaultValue: "creation",
    },
    visibility: {
      name: "Visibility",
      dataType: "string",
      enumValues: [
        { id: "public", label: "Public", color: "grayLighter" },
        { id: "suppressed", label: "Suppressed", color: "yellowLight" },
        { id: "hidden", label: "Hidden", color: "redDark" },
      ],
      defaultValue: "public",
      validation: { required: true },
    },
    title: {
      name: "Title",
      dataType: "string",
      validation: { required: true },
    },
    description: {
      name: "Description",
      dataType: "string",
      multiline: true,
    },
    creator: {
      name: "Creator",
      dataType: "reference",
      path: "users",
      validation: { required: true },
    },
    image: buildProperty({ 
      name: "Image",
      dataType: "string",
      storage: {
          storagePath: "images",
          acceptedFiles: ["image/*"],
          storeUrl: true,
      },
    }),
    variationOf: {
      name: "Variation Of",
      description: "Optional parent creation",
      dataType: "reference",
      path: "items",
      forceFilter: {
        type: ["==", "creation"],
      },
    },
    ingredients: {
      name: "Ingredients",
      description: "Every ingredient in the creation",
      dataType: "array",
      of: {
        dataType: "reference",
        path: "items",
        forceFilter: {
          type: ["==", "ingredient"],
        },
      },
    },
    tags: {
      name: "Tags",
      description: "Categories, techniques, regions etc.",
      dataType: "array",
      of: {
        dataType: "reference",
        path: "items",
        forceFilter: {
          type: ["!=", "ingredient"],
        },
      },
      validation: { required: true },
    },
    recipe: {
      name: "Recipe",
      description: "Optional recipe",
      dataType: "reference",
      path: "recipes",
    },
    linkUrl: {
      name: "Link URL",
      dataType: "string",
      url: true,
    },
    linkText: {
      name: "Link Text",
      dataType: "string",
      defaultValue: "Recipe",
    },
    created: {
      name: "Created",
      dataType: "date",
      validation: { required: true },
      autoValue: "on_create",
    },
    updated: {
      name: "Updated",
      dataType: "date",
      autoValue: "on_update",
    },
  },
});

type CombinationPost = {
  type: string;
  visibility: string;
  title: string;
  description: string;
  creator: EntityReference;
  key: EntityReference;
  ingredients: EntityReference[];
  tags: EntityReference[];
  linkUrl: string;
  linkText: string;
  created: Date;
  updated: Date;
}

export const combinationPosts = buildCollection<CombinationPost>({
  name: "Combinations",
  icon: "ViewStream",
  group: "User Content",
  singularName: "Combination",
  path: "posts",
  alias: "combinations",
  forceFilter: { type: ["==", "combination"] },
  // initialSort: ["created", "desc"], //doesn't work in conjunction with force filter
  textSearchEnabled: true,
  permissions: ({ authController }) => ({
      edit: true,
      create: true,
      delete: true
  }),
  properties: {
    type: {
      name: "Type",
      dataType: "string",
      validation: { required: true },
      description: "Preset in this view",
      enumValues: {
        combination: "Combination",
      },
      defaultValue: "combination",
    },
    visibility: {
      name: "Visibility",
      dataType: "string",
      enumValues: [
        { id: "public", label: "Public", color: "grayLighter" },
        { id: "suppressed", label: "Suppressed", color: "yellowLight" },
        { id: "hidden", label: "Hidden", color: "redDark" },
      ],
      defaultValue: "public",
      validation: { required: true },
    },
    creator: {
      name: "Creator",
      dataType: "reference",
      path: "users",
      validation: { required: true },
    },
    title: {
      name: "Title",
      description: "Always: Ingredient 1 + Ingredient 2 etc.",
      dataType: "string",
      validation: { required: true },
    },
    description: {
      name: "Description",
      dataType: "string",
      multiline: true,
    },
    key: {
      name: "Key",
      description: "Starting point for combination",
      dataType: "reference",
      path: "items",
    },
    ingredients: {
      name: "Ingredients",
      description: "At least 2 required, include key ingredient first",
      dataType: "array",
      of: {
        dataType: "reference",
        path: "items",
        forceFilter: {
          type: ["==", "ingredient"],
        },
      },
      validation: { required: true },
    },
    tags: {
      name: "Tags",
      description: "Regions, techniques etc.",
      dataType: "array",
      of: {
        dataType: "reference",
        path: "items",
        forceFilter: {
          type: ["!=", "ingredient"],
        },
      },
      validation: { required: true },
    },
    linkText: {
      name: "Link Text",
      description: "For attribution",
      dataType: "string",
    },
    linkUrl: {
      name: "Link URL",
      description: "For attribution link",
      dataType: "string",
      url: true,
    },
    created: {
      name: "Created",
      dataType: "date",
      autoValue: "on_create",
      validation: { required: true },
    },
    updated: {
      name: "Updated",
      dataType: "date",
      autoValue: "on_update",
    },
  },
});


type DiscussionPost = {
  type: string;
  visibility: string;
  creator: EntityReference;
  title: string;
  description: string;
  tags: EntityReference[];
  created: Date;
  updated: Date;
}

export const discussionPosts = buildCollection<DiscussionPost>({
  name: "Discussions",
  icon: "Message",
  group: "User Content",
  singularName: "Post",
  path: "posts",
  alias: "discussions",
  forceFilter: { type: ["==", "discussion"] },
  // initialSort: ["created", "desc"], //doesn't work in conjunction with force filter
  textSearchEnabled: true,
  permissions: ({ authController }) => ({
      edit: true,
      create: true,
      delete: true
  }),
  properties: {
    type: {
      name: "Type",
      dataType: "string",
      validation: { required: true },
      description: "Preset in this view",
      enumValues: {
        discussion: "Discussion",
      },
      defaultValue: "discussion",
    },
    visibility: {
      name: "Visibility",
      dataType: "string",
      enumValues: [
        { id: "public", label: "Public", color: "grayLighter" },
        { id: "suppressed", label: "Suppressed", color: "yellowLight" },
        { id: "hidden", label: "Hidden", color: "redDark" },
      ],
      defaultValue: "public",
      validation: { required: true },
    },
    creator: {
      name: "Creator",
      dataType: "reference",
      path: "users",
      validation: { required: true },
    },
    title: {
      name: "Title",
      dataType: "string",
      validation: { required: true },
    },
    description: {
      name: "Description",
      dataType: "string",
      multiline: true,
    },
    tags: {
      name: "Tags",
      description: "Categories, techniques, regions etc.",
      dataType: "array",
      of: {
        dataType: "reference",
        path: "items",
        forceFilter: {
          type: ["!=", "ingredient"],
        },
      },
      validation: { required: true },
    },
    created: {
      name: "Created",
      dataType: "date",
      validation: { required: true },
      autoValue: "on_create",
    },
    updated: {
      name: "Updated",
      dataType: "date",
      autoValue: "on_update",
    },
  },
});