/*SUGGESTSIONS COLLECTION*/
import { buildCollection, buildProperty } from "firecms";

type AppUser = {
  userName: string;
  status: string;
  image: string;
  fullName: string;
  location: string;
  bio: string;
  created: Date;
  updated: Date;
}

const userPreferences = buildCollection({
  name: "Preferences",
  icon: "Email",
  singularName: "Preferences",
  path: "preferences",
  properties: {
    email: {
      name: "Email",
      dataType: "string",
      validation: { required: true },
      email: true,
    },
    emailVerified: {
      name: "Email Verified",
      dataType: "boolean",
      validation: { required: true },
    },
    newsletter: {
      name: "Newsletter",
      dataType: "boolean",
      validation: { required: true },
    },
  }
});

const userBookmarks = buildCollection({
  name: "Bookmarks",
  icon: "Bookmarks",
  singularName: "Bookmark",
  path: "bookmarks",
  properties: {
    type: {
        name: "Type",
        dataType: "string",
        enumValues: {
          bookmark: "Bookmark",
          folder: "Folder",
          notes: "Notes",
        },
        defaultValue: "bookmark",
        validation: { required: true },
    },
    target: {
      name: "Target",
      dataType: "reference",
      path: "items",
    },
    title: {
      name: "Title",
      dataType: "string",
    },
    image: {
      name: "Image",
      dataType: "string",
    },
    folder: {
      name: "Folder ID",
      dataType: "string",
    },
    text: {
      name: "Text",
      dataType: "string",
    },
    created: {
      name: "Created",
      dataType: "date",
      validation: { required: true },
      autoValue: "on_create",
    },
  }
});

export const users = buildCollection<AppUser>({
  name: "Users",
  icon: "People",
  group: "User Management",
  singularName: "User",
  path: "users",
  customId: false,
  initialSort: ["updated", "desc"],
  textSearchEnabled: true,
  permissions: ({ authController }) => ({
    edit: true,
    create: true,
    delete: false,
  }),
  subcollections: [
    userPreferences,
    userBookmarks,
  ],
  properties: {
    userName: {
      name: "User Name",
      dataType: "string",
      validation: { required: true },
    },
    status: {
      name: "Status",
      dataType: "string",
      enumValues: [
        { id: "active", label: "Active", color: "grayLighter" },
        { id: "deactivated", label: "Deactivated", color: "yellowDark" },
        { id: "blocked", label: "Blocked", color: "redDark" },
      ],
      defaultValue: "active",
      validation: { required: true },
    },
    image: buildProperty({ 
      name: "Image",
      dataType: "string",
      storage: {
        storagePath: "images",
        acceptedFiles: ["image/*"],
        storeUrl: true,
      },
    }),
    fullName: {
      name: "Full Name",
      dataType: "string",
    },
    location: {
      name: "Location",
      dataType: "string",
    },
    bio: {
      name: "Bio",
      dataType: "string",
      multiline: true,
    },
    created: {
      name: "Created",
      dataType: "date",
      validation: { required: true },
      autoValue: "on_create",
    },
    updated: {
      name: "Updated",
      dataType: "date",
    },
  },
});