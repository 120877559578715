/*SUGGESTSIONS COLLECTION*/
import { buildCollection, EntityReference } from "firecms";

type Suggestion = {
  type: string;
  components: EntityReference[];
  text: string;
  created: Date;
  status: string;
  updated: Date;
}

export const suggestions = buildCollection<Suggestion>({
  name: "Suggestions",
  icon: "School",
  group: "Chef Suggestions",
  singularName: "Suggestion",
  path: "suggestions",
  //forceFilter: { type: ["==", "recipes"] },
  initialSort: ["created", "desc"], //doesn't work in conjunction with force filter
  textSearchEnabled: true,
  permissions: ({ authController }) => ({
      edit: true,
      create: true,
      delete: true
  }),
  properties: {
    type: {
      name: "Type",
      dataType: "string",
      description: "Preset in this view",
      enumValues: {
        howto: "How To Make",
        recipes: "What To Make",
        sides: "On The Side",
        wines: "To Drink",
        sweets: "Sweets",
      },
      defaultValue: "recipes",
      validation: { required: true },
    },
    components: {
      name: "Components",
      description: "Combinator inputs that generated this suggestion",
      dataType: "array",
      of: {
        dataType: "reference",
        path: "items",
        forceFilter: {
          type: ["!=", "internal"],
        },
      },
      validation: { required: true },
    },
    text: {
      name: "Text",
      dataType: "string",
      multiline: true,
      validation: { required: true },
    },
    created: {
      name: "Created",
      dataType: "date",
      validation: { required: true },
      autoValue: "on_create",
    },
    status: {
      name: "Status",
      dataType: "string",
      validation: { required: true },
      enumValues: {
        new: "New",
        reviewed: "Reviewed",
      },
      defaultValue: "new",
    },
    updated: {
      name: "Updated",
      dataType: "date",
      autoValue: "on_update",
    },
  },
});