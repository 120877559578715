/*REPORTS COLLECTION*/
import { buildCollection, EntityReference } from "firecms";

type Report = {
  status: string;
  creator: EntityReference;
  target: EntityReference;
  text: string;
  created: Date;
  updated: Date;
  notes: string;
}

export const reports = buildCollection<Report>({
  name: "Reports",
  icon: "Report",
  group: "User Management",
  singularName: "Report",
  path: "reports",
  customId: false,
  initialSort: ["created", "desc"],
  textSearchEnabled: false,
  permissions: ({ authController }) => ({
    edit: true,
    create: false,
    delete: true,
  }),
  properties: {
    status: {
      name: "Status",
      dataType: "string",
      enumValues: [
        { id: "new", label: "New", color: "redDark" },
        { id: "processed", label: "Processed", color: "yellowDark" },
        { id: "confirmed", label: "Confirmed", color: "greenDark" },
        { id: "invalid", label: "Invalid", color: "grayLighter" },
      ],
      defaultValue: "new",
      validation: { required: true },
    },
    creator: {
      name: "Creator",
      dataType: "reference",
      path: "users",
      validation: { required: true },
    },
    target: {
      name: "Target",
      dataType: "reference",
      path: "posts",
      validation: { required: true },
    },
    text: {
      name: "Text",
      dataType: "string",
      multiline: true,
    },
    created: {
      name: "Created",
      dataType: "date",
      validation: { required: true },
      autoValue: "on_create",
    },
    updated: {
      name: "Updated",
      dataType: "date",
      autoValue: "on_update",
    },
    notes: {
      name: "Notes",
      dataType: "string",
      multiline: true,
    },
  },
});