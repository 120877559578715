/*CONSTANTS COLLECTION*/
import { buildCollection, EntityReference } from "firecms";

type Constants = {
  groups: Map<String,EntityReference[]>;
}
export const constants = buildCollection<Constants>({
  name: "Constants",
  icon: "DataArray",
  group: "Managed Content",
  singularName: "Constants",
  path: "constants",
  customId: true,
  textSearchEnabled: false,
  permissions: ({ authController }) => ({
    edit: true,
    create: true,
    delete: false,
  }),
  properties: {
    groups: {
      name: "Constants Groups",
      dataType: "map",
      keyValue: true,
    },
  },
});