/*ARTICLES COLLECTION*/
import { buildCollection, buildProperty, EntityReference } from "firecms";
/*FOR PREVIEW*/
import { useEffect, useState } from "react";
import { Box, Container, Typography } from "@mui/material";
import { EntityCustomViewParams, Markdown, useStorageSource } from "firecms";

type Article = {
  status: string;
  header_image: string;
  title: string;
  creator: EntityReference;
  images: string[];
  body: string;
  tags: EntityReference[];
  created: Date;
  updated: Date;
}

export const articles = buildCollection<Article>({
    name: "Articles",
    singularName: "Article",
    icon: "Article",
    path: "articles",
    views: [{
        path: "preview",
        name: "Preview",
        Builder: ArticlePreview
    }],
    properties: {
        status: buildProperty(({ values }) => ({
          name: "Status",
          validation: { required: true },
          dataType: "string",
          columnWidth: 140,
          enumValues: {
              published: {
                  id: "published",
                  label: "Published",
                  disabled: !values.header_image
              },
              draft: "Draft"
          },
          defaultValue: "draft"
        })),
        header_image: buildProperty({
          name: "Header image",
          dataType: "string",
          storage: {
              mediaType: "image",
              storagePath: "images",
              acceptedFiles: ["image/*"],
              metadata: {
                  cacheControl: "max-age=1000000"
              }
          }
        }),
        title: buildProperty({
            name: "Title",
            validation: { required: true },
            dataType: "string"
        }),
        creator: buildProperty({
          name: "Creator",
          dataType: "reference",
          path: "users",
          validation: { required: true },
        }),
        images: buildProperty({
          name: "Images",
          description: "This field allows uploading multiple images at once and reordering",
          dataType: "array",
          of: buildProperty({
              dataType: "string",
              storage: {
                  mediaType: "image",
                  storagePath: "images",
                  acceptedFiles: ["image/*"],
                  metadata: {
                      cacheControl: "max-age=1000000"
                  }
              }
          }),
        }),
        body: buildProperty({
          name: "Body",
          description: "Enter the body of the article, copy image urls to inlcude inline",
          dataType: "string",
          markdown: true,
        }),
        tags: buildProperty({
          name: "Tags",
          description: "Categories, techniques, regions etc.",
          dataType: "array",
          of: {
            dataType: "reference",
            path: "items",
            forceFilter: {
              type: ["!=", "ingredient"],
            },
          },
        }),
        created: buildProperty({
            name: "Created on",
            dataType: "date",
            autoValue: "on_create"
        }),
        updated: buildProperty({
          name: "Updated on",
          dataType: "date",
          autoValue: "on_update"
        }),
    }
})

/**
 * This is a view used to render the content of an article.
 * It is bound to the data that is modified in the form.
 */

export function ArticlePreview({ modifiedValues }: EntityCustomViewParams<Article>) {

  const storage = useStorageSource();

  const [headerUrl, setHeaderUrl] = useState<string | undefined>();
  useEffect(() => {
      if (modifiedValues?.header_image) {
          storage.getDownloadURL(modifiedValues.header_image)
              .then((res) => setHeaderUrl(res.url));
      }
  }, [storage, modifiedValues?.header_image]);

  return (
      <Box>
          {headerUrl && <img
              alt={"Header"}
              style={{
                  width: "100%",
                  maxHeight: "300px",
                  objectFit: "cover"
              }}
              src={headerUrl}
          />}

          <Container maxWidth={"md"}
                     sx={{
                         alignItems: "center",
                         justifyItems: "center",
                         display: "flex",
                         flexDirection: "column"
                     }}>
              {modifiedValues?.title && 
                <Typography variant={"h3"} sx={{
                    marginTop: "40px",
                    marginBottom: "20px"
                }}>
                    {modifiedValues.title}
                </Typography>
              }                                                                                                  
              
              {modifiedValues?.body && 
                <Box mt={6} mb={6}>
                    <Markdown source={modifiedValues.body}/>
                </Box>
              }

          </Container>

      </Box>
  );

}