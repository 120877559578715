
/**
 * Firebase Config
 */

const firebaseConfig = {
  apiKey: "AIzaSyB9wiUIUBupwgbAtDkUfk7jyVJs9eaSaF0",
  authDomain: "tastematch-app.firebaseapp.com",
  projectId: "tastematch-app",
  storageBucket: "tastematch-app.appspot.com",
  messagingSenderId: "818150351143",
  appId: "1:818150351143:web:37a6e7a0db9769276dc902",
  measurementId: "G-CP4XH0X1VC"
}

export{firebaseConfig}