/*MATCHES COLLECTION*/
import { buildCollection, buildProperty, EntityReference } from "firecms";

type Match = {
  key: EntityReference;
  keyType: string;
  keyCategory: string;
  match: EntityReference;
  matchType: string;
  matchCategory: string;
  score: number;
}

const avoidScore = -1;

export const matches = buildCollection<Match>({
  name: "Matches",
  icon: "JoinInner",
  group: "Managed Content",
  singularName: "Match",
  path: "matches",
  customId: true,
  textSearchEnabled: true,
  permissions: ({ authController }) => ({
      edit: true,
      create: true,
      delete: true
  }),
  properties: {
    key: {
      name: "Key",
      description: "Choose a key",
      dataType: "reference",
      path: "items",
      validation: { required: true },
    },
    keyType: {
      name: "Key Item Type",
      dataType: "string",
      enumValues: {
        ingredient: "Ingredient",
        category: "Category",
        creationCategory: "Creation Category",
        creation: "Creation",
        profile: "Profile",
        region: "Region",
        style: "Style",
        technique: "Technique",
        diet: "Diet",
        event: "Event",
        season: "Season",
        meal: "Meal",
        internal: "Internal",
      },
      validation: { required: true },
    },
    keyCategory: {
      name: "Key Category",
      dataType: "string",
    },
    match: {
      name: "Match",
      description: "Choose a match",
      dataType: "reference",
      path: "items",
    },
    matchType: {
      name: "Match Item Type",
      dataType: "string",
      enumValues: {
        ingredient: "Ingredient",
        category: "Category",
        creationCategory: "Creation Category",
        creation: "Creation",
        profile: "Profile",
        region: "Region",
        style: "Style",
        technique: "Technique",
        diet: "Diet",
        event: "Event",
        season: "Season",
        meal: "Meal",
        internal: "Internal",
      },
      validation: { required: true },
    },
    matchCategory: {
      name: "Match Category",
      dataType: "string",
    },
    score: {
      name: "Score",
      description: "Choose a score",
      dataType: "number",
      enumValues: {
        1: "1",
        5: "5",
        20: "20",
        60: "60",
        avoidScore: "-1",  //using preset var
      },
      defaultValue: 1,
      validation: { required: true },
    },
  },
});