/**
 * Use this controller to return a list of ids from a search index, given a
 * `path` and a `searchString`.
 * Firestore does not support text search directly so we need to rely on an external
 * index, such as Typesense.
 * Note that you will get text search requests for collections that have the
 * `textSearchEnabled` flag set to `true`.
 * @category Firebase
 * @category Typesense
 */

/**
Reference example using Algolia here:
https://github.com/Camberi/firecms/blob/2d60fba/src/firebase_app/models/text_search.ts#L22
*/

import Typesense from "typesense";

const typesense = new Typesense.Client({
  nodes: [
      {
      host: '68dokl7nyaqvp2bwp-1.a1.typesense.net',
      port: 443,
      protocol: 'https'
      }
  ],
  apiKey:`WdIU1GZj4eIH8W3udaXg9eArg7PS2Mjp`, // search only, limited 
  numRetries: 3, // A total of 4 tries (1 original try + 3 retries)
  connectionTimeoutSeconds: 10,
  retryIntervalSeconds: 0.1,
  healthcheckIntervalSeconds: 2,
});

export declare type TypesenseTextSearchController = (props: {
  path: string;
  searchString: string;
}) => Promise<readonly string[]> | undefined;

export function performTypesenseTextSearch( path: string, searchString: string ): Promise<readonly string[]> {
	let queryBy = 'title';
	if (path === 'matches') {  //need to customize for the matches collection, which has no title attribute
		searchString = "items/" + searchString;  // prefix with the path to the reference objects
		queryBy = 'key.path,match.path';
	}
  if (path === 'users') {  //need to customize for the users collection, which has no title attribute
		queryBy = 'userName,fullName';
	}
  if (path === 'suggestions') {  //need to customize for the suggestions collection, which has no title attribute
		queryBy = 'text';
	}
  let searchParams = {'q' : searchString, 'query_by': queryBy, 'per_page': 100};
  return typesense.collections(path).documents()
      .search(searchParams)
      .then(({ hits }: any) => {
          return hits.map((hit: any) => hit.document.id as string);
      })
      .catch((err: any) => {
          console.log(err);
          return [];
      });
}

