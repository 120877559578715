/* LOCALES COLLECTION FOR INTERNATIONALIZATION */
import { buildCollection } from "firecms";

const locales = {
  "en-US": "English (United States)",
  "es-ES": "Spanish (Spain)",
  "de-DE": "German"
}

export const localeCollection = buildCollection({
  path: "locale",
  customId: locales,
  name: "Locales",
  singularName: "Locales",
  properties: {
    name: {
        name: "Title",
        dataType: "string",
        validation: { required: true },
    },
    selectable: {
        name: "Selectable",
        dataType: "boolean",
        description: "Is this locale selectable?",
    },
  }
});