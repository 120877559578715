import "typeface-rubik";
import "@fontsource/ibm-plex-mono";
import { useCallback } from "react";
import { User as FirebaseUser } from "firebase/auth";
import { firebaseConfig } from "./config/firebase";
import { Authenticator, FirebaseCMSApp } from "firecms";
import { TypesenseTextSearchController, performTypesenseTextSearch } from './config/typesense';

import { items } from "./collections/items";
import { matches } from "./collections/matches";
import { lists } from "./collections/lists";
import { constants } from "./collections/constants";
import { posts, creationPosts, combinationPosts, discussionPosts } from "./collections/posts";
import { suggestions } from "./collections/suggestions";
import { recipes } from "./collections/recipes";
import { users } from "./collections/users";
import { activities } from "./collections/activities";
import { reports } from "./collections/reports";
import { articles } from "./collections/articles";

const textSearchController: TypesenseTextSearchController = ({ path, searchString }) => {
    return performTypesenseTextSearch(path, searchString);
  }

export default function App() {

  const myAuthenticator: Authenticator<FirebaseUser> = useCallback(async ({ user, authController}) => 
  {
    let allowed = false;
    const idTokenResult = await user?.getIdTokenResult();
    const roles = idTokenResult?.claims.roles;
    if (roles && roles.includes('ADMIN')) {
      // Allowed
      authController.setExtra(roles);
      allowed = true;
    } else {
      // Not allowed
      throw Error("That account is not authorized");
    }
    return allowed; 
  }, []);

  return <FirebaseCMSApp
    name={"TasteMatch"}
    logo={"/tm-logo.svg"}
    firebaseConfig={ firebaseConfig }
    authentication={ myAuthenticator }
    textSearchController={ textSearchController }
    collections={[ 
      items,
      matches,
      lists,
      constants,
      posts,
      creationPosts,
      combinationPosts,
      discussionPosts,
      suggestions,
      recipes,
      users,
      activities,
      reports,
      articles,
    ]}
  />;
}
