/*RECIPES COLLECTION*/
import { buildCollection, EntityReference } from "firecms";

type Recipe = {
  title: string;
  aka: string;
  components: EntityReference[];
  keyComponents: EntityReference[];
  text: string;
  created: Date;
  status: string;
  redirect: EntityReference;
  updated: Date;
}

export const recipes = buildCollection<Recipe>({
  name: "Recipes",
  icon: "MenuBook",
  group: "Chef Suggestions",
  singularName: "Recipe",
  path: "recipes",
  //forceFilter: { type: ["==", "recipes"] },
  initialSort: ["created", "desc"], //doesn't work in conjunction with force filter
  textSearchEnabled: true,
  permissions: ({ authController }) => ({
      edit: true,
      create: true,
      delete: true
  }),
  properties: {
    title: {
      name: "Title",
      dataType: "string",
      validation: { required: true },
    },
    aka: {
      name: "Also known as",
      dataType: "string",
    },
    components: {
      name: "Components",
      description: "Combinator inputs that led to this recipe being suggested",
      dataType: "array",
      of: {
        dataType: "reference",
        path: "items",
        forceFilter: {
          type: ["!=", "internal"],
        },
      },
      validation: { required: true },
    },
    keyComponents: {
      name: "Key Components",
      description: "Key components for this recipe",
      dataType: "array",
      of: {
        dataType: "reference",
        path: "items",
        forceFilter: {
          type: ["!=", "internal"],
        },
      },
    },
    text: {
      name: "Text",
      dataType: "string",
      multiline: true,
      validation: { required: true },
    },
    created: {
      name: "Created",
      dataType: "date",
      validation: { required: true },
      autoValue: "on_create",
    },
    status: {
      name: "Status",
      dataType: "string",
      validation: { required: true },
      enumValues: {
        new: "New",
        reviewed: "Reviewed",
        redirected: "Redirected",
      },
      defaultValue: "new",
    },
    redirect: {
      name: "Redirect To",
      description: "Recipe to redirect this to",
      dataType: "reference",
      path: "recipes",
    },
    updated: {
      name: "Updated",
      dataType: "date",
      autoValue: "on_create",
    },
  },
});