/*ACTIVITIES COLLECTION*/
import { buildCollection, buildProperty, EntityReference } from "firecms";

type Activity = {
  type: string;
  visibility: string;
  creator: EntityReference;
  creatorName: string;
  text: string;
  target: EntityReference;
  targetCreator: EntityReference;
  targetImage: string;
  context: EntityReference;
  contextCreator: EntityReference;
  created: Date;
}

export const activities = buildCollection<Activity>({
  name: "Activity",
  icon: "RoomService",
  group: "User Management",
  singularName: "Activity",
  path: "activity",
  customId: false,
  initialSort: ["created", "desc"],
  textSearchEnabled: false,
  permissions: ({ authController }) => ({
    edit: true,
    create: false,
    delete: true,
  }),
  properties: {
    creator: {
      name: "Creator",
      dataType: "reference",
      path: "users",
      validation: { required: true },
    },
    creatorName: {
      name: "Creator Name",
      dataType: "string",
      validation: { required: true },
    },
    type: {
      name: "Type",
      dataType: "string",
      validation: { required: true },
      enumValues: {
        comment: "Comment",
        like: "Like",
        follow: "Follow",
        mention: "Mention"
      },
    },
    visibility: {
      name: "Visibility",
      dataType: "string",
      enumValues: [
        { id: "public", label: "Public", color: "grayLighter" },
        { id: "suppressed", label: "Suppressed", color: "yellowLight" },
        { id: "hidden", label: "Hidden", color: "redDark" },
      ],
      defaultValue: "public",
      validation: { required: true },
    },
    text: {
      name: "Text",
      dataType: "string",
      multiline: true,
    },
    target: {
      name: "Target",
      dataType: "reference",
      path: "posts",
      validation: { required: true },
    },
    targetCreator: {
      name: "Target Creator",
      dataType: "reference",
      path: "users",
      validation: { required: true },
    },
    targetImage: buildProperty({ 
      name: "Target Image",
      dataType: "string",
      storage: {
        storagePath: "images",
        acceptedFiles: ["image/*"],
        storeUrl: true,
      },
    }),
    context: {
      name: "Context",
      dataType: "reference",
      path: "posts",
    },
    contextCreator: {
      name: "Context Creator",
      dataType: "reference",
      path: "users",
    },
    created: {
      name: "Created",
      dataType: "date",
      validation: { required: true },
      autoValue: "on_create",
    },
  },
});